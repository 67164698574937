
export const ENCRYPTION_KEY = '398h=85l493483sjdkjsbh87h38498==';

export const NAIRA_SIGN = '₦';

// export const API_URL = "http://localhost:9004"
export const API_URL = "https://progressive-harriet-upperqase-76043389.koyeb.app" //"https://imaginative-marmoset-genesisapp-1e590d8e.koyeb.app" //"https://genesishotels.onrender.com"; //"https://genesis-hotels-baybl.ondigitalocean.app"

export const COOKIE_KEY = "admin_Token"

export const OWS_BASE_URL = "https://genesishotel.online/api"
 // "http://203.161.62.42/api" // "http://198.187.30.147/api" //"https://bookings.genesishotels.online/api"